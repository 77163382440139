import React from "react";

const TermsOfUsePage = () => (
  <>
    <h1>Termini di utilizzo</h1>
    <p>Termini di utilizzo text.</p>
    <a href="/">Torna alla home</a>
  </>
);

export default TermsOfUsePage;
